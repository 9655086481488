import React from 'react';

export default function Completed() {
  return (
    <div>
      <h1>Video Visit Complete </h1>
      <p>Thank you for joining today. Your session has ended. Please close this tab/window.</p>
    </div>
  );
}
