import React from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useTranslation } from 'react-i18next';

export default function ReconnectingNotification() {
  const roomState = useRoomState();
  const { t } = useTranslation();

  return (
    <Snackbar
      variant="error"
      headline={`${t('notifications.reconnecting.headline')}:`}
      message={`${t('notifications.reconnecting.message')}...`}
      open={roomState === 'reconnecting'}
    />
  );
}
