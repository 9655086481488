import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      buttons: {
        gallery: 'Gallery',
        continue: 'Continue',
        submit: 'Submit',
        'end-call': 'Disconnect',
        audio: {
          'no-audio': 'No Audio',
          mute: 'Mute',
          unmute: 'Unmute',
        },
        video: {
          'no-video': 'No Video',
          stop: 'Stop Video',
          start: 'Start Video',
        },
        screenshare: {
          start: 'Share Screen',
          stop: 'Stop Sharing Screen',
          'in-progress': 'Cannot share screen when another user is sharing',
          'not-supported': 'Screen sharing is not supported with this browser',
        },
        done: 'Done',
      },
      participant: {
        'recording-tooltip':
          "All participants' audio and video is currently being recorded. Visit the app settings to stop recording.",
        recording: 'Recording',
        reconecting: 'Reconnecting',
      },
      modals: {
        error: {
          'error-code': 'Error Code: %{code}',
        },
        about: {
          browser: 'Browser supported: %{browser}',
          'sdk-version': 'SDK Version: %{version}',
          'app-version': 'App Version: %{version}',
          'deployed-tag': 'Deployed Tag: %{tag}',
          'deployed-hash': 'Deployed Commit Hash: %{hash}',
          'room-type': 'Room Type: %{type}',
        },
      },
      login: {
        passcode: 'Passcode',
        'sign-in-twillio': 'Sign in using your Twillio Google account',
        'sign-in-google': 'Sign in using your Google account',
        'sign-in-room': 'Sign in to join a room',
        'enter-passcode': 'Enter passcode to join a room',
      },
      device: {
        joining: 'Joining Meeting',
        'join-now': 'Join Now',
      },
      prejoin: {
        'join-room-title': 'Join a Room',
        'continue-btn': 'Continue',
        'name-label': 'Your name',
        'room-label': 'Room name',
        'enter-room': "Enter the name of a room you'd like to join.",
        'enter-room-name': "Enter your name and the name of a room you'd like to join",
        'default-audio-output': 'System Default Audio Output',
      },
      settings: {
        more: 'More',
        settings: 'Settings',
        about: 'About',
        connection: 'Connection Settings',
        'audio-video': 'Audio and Video Settings',
        low: 'Low',
        standard: 'Standard',
        high: 'High',
        predicted: 'Predicted',
        detected: 'Detected',
        disabled: 'Disabled',
        grid: 'Grid',
        collaboration: 'Collaboration',
        presentation: 'Presentation',
        auto: 'Auto',
        manual: 'Manual',
        default: 'Server Default',
      },
      labels: {
        'max-bitrate': 'Max Audio Bitrate',
        'no-local-audio': 'No Local Audio',
        'audio-input': 'Audio Input',
        'audio-output': 'Audio Output',
        'no-local-video': 'No Local Video',
        'video-input': 'Video Input',
      },
      placeholders: {
        'blank-no-limit': 'Leave blank for no limit',
      },
    },
    notifications: {
      reconnecting: {
        headline: 'Connection Lost',
        message: 'Reconnecting to room',
      },
      recording: {
        headline: {
          started: 'Recording has started',
          progress: 'Recording is in Progress',
          complete: 'Recording complete',
        },
      },
    },
    warnings: {
      'browser-not-supported': 'Browser or context not supported',
      'open-supported-prompt': 'Please open this application in one of the <1>supported browsers</1>.',
      'ensure-supported-browser':
        'If you are using a supported browser, please ensure that this app is served over a <1>secure context</1> (e.g. https or localhost)',
    },
  },
  es: {
    translation: {
      buttons: {
        gallery: 'Galería',
        continue: 'Continuar',
        'end-call': 'Desconectar',
        audio: {
          'no-audio': 'Sin Audio',
          mute: 'Mutear',
          unmute: 'Desmutear',
        },
        video: {
          'no-video': 'Sin Video',
          stop: 'Detener Video',
          start: 'Empezar Video',
        },
        screenshare: {
          start: 'Compartir Pantalla',
          stop: 'Detener Compartir Pantalla',
          'in-progress': 'No se puede compartir pantalla si alguien más esta compartiendo pantalla',
          'not-supported': 'Este buscador no soporta Compartir Pantalla',
        },
        done: 'Finalizar',
      },
      modals: {
        error: {
          'error-code': 'Código de Error: %{code}',
        },
        about: {
          browser: 'Navegador Compatible: %{browser}',
          'sdk-version': 'Versión SDK: %{version}',
          'app-version': 'Versión App: %{version}',
          'deployed-tag': 'Tag Implementada: %{tag}',
          'deployed-hash': 'Commit Hash Implementado: %{hash}',
          'room-type': 'Tipo de Cuarto: %{type}',
        },
      },
      device: {
        joining: 'Uniendose a Reunion',
        'join-now': 'Unirse',
      },
      prejoin: {
        'join-room-title': 'Únase a un Cuarto',
        'continue-btn': 'Continuar',
        'name-label': 'Su nombre',
        'room-label': 'Nombre de Cuarto',
        'enter-room': 'Ingrese el nombre de un Cuarto al que le gustaría unirse.',
        'enter-room-name': 'Ingrese su nombre y el nombre de un Cuarto al que le gustaría unirse.',
      },
      login: {
        passcode: 'Passcode',
        'sign-in-twillio': 'Ingrese usando su cuenta de Twillio Google',
        'sign-in-google': 'Ingrese usando su cuenta de Google',
        'sign-in-room': 'Ingrese para unirse a un Cuarto',
        'enter-passcode': 'Escriba un passcode para unirse a un Cuarto',
      },
      settings: {
        more: 'Más',
        settings: 'Ajustes',
        about: 'Acerca de',
        connection: 'Ajustes de Conexión',
        'audio-video': 'Ajustes de Audio y Video',
        low: 'Bajo',
        standard: 'Estandar',
        high: 'Alto',
        predicted: 'Predecido',
        detected: 'Detectado',
        disabled: 'Desactivado',
        grid: 'Malla',
        collaboration: 'Colaboración',
        presentation: 'Presentación',
        auto: 'Auto',
        manual: 'Manual',
        default: 'Por defecto',
      },
      participant: {
        'recording-tooltip':
          'El video y audio de todos los participantes esta siendo grabado. Vaya a los ajustes de la aplicación para dejar de grabar.',
        recording: 'Grabando',
        reconecting: 'Reconectando',
      },
      labels: {
        'max-bitrate': 'Bitrate Máximo de Audio',
        'no-local-audio': 'No hay Audio Local',
        'audio-input': 'Entrada de Audio',
        'audio-output': 'Salida de Audio',
        'default-audio-output': 'Salida de Audio por Defecto del Sistema',
        'no-local-video': 'No hay Video Local',
        'video-input': 'Entrada de Video',
      },
      placeholders: {
        'blank-no-limit': 'Dejar en blanco para no limitar',
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: window.navigator.language || 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
